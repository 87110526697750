@use "sass:map";

app-activity-edition-true-false {
    .grid-fields {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: minmax(0, 1fr);
        justify-content: center;
        align-items: center;
        gap: $gap-m;
    }

    .field-wrapper > * {
        @include button('tertiary');
        @include radio-in-button;
    }




    mat-icon {
        margin-left: auto;
    }

    app-activity-edition-radio-button {
        display: flex;
        flex: 1;
        > span {
            padding: 0 $gap-m;
        }
    }
}