app-activity-edition-preview-sort-mat {
    @include default-typology-preview-layout;

    .activity-edition-preview-sort-mat__answer-button,
    .activity-edition-preview-sort-mat__answer-button-placeholder {}

    .activity-edition-preview-sort-mat__number-button,
    .activity-edition-preview-sort-mat__number-button-placeholder {
        font-size: 3rem;
        font-weight: 500;
        line-height: 1;
        span {
            overflow-y: hidden;
        }
    }
}