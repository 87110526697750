// --------------------------------------------------
// VARIABLES
// --------------------------------------------------

$activity-edition-preview__activable-element--border-width: 4px !default;
$activity-edition-preview__activable-element--padding: 0.6rem !default;
$activity-edition-preview__activable-element--translateX: -1.2rem !default;

// --------------------------------------------------
// MIXINS
// --------------------------------------------------

@mixin placeholder-element-style {
    transition: opacity 100ms ease-in-out,
                filter 100ms ease-in-out;

    &--placeholder {
        opacity: 0.5;
        filter: blur(1px);
    }
}

@mixin activable-element-style(
    $border-width: $activity-edition-preview__activable-element--border-width,
    $padding: $activity-edition-preview__activable-element--padding,
    $translateX: $activity-edition-preview__activable-element--translateX,
) {
    position: relative;
    padding: $border-width calc($border-width + $padding);

    &::before,
    &::after { // Active step border & number
        content: '';
        position: absolute;
        opacity: 0;
        transition: opacity 100ms ease-in-out,
                    transform 100ms ease-in-out;
        pointer-events: none;
    }

    &::before { // Active step border
        inset: 0;
        height: calc(100% + $border-width * 2 + $padding * 2);
        border: $border-width solid #FFB701;
        border-radius: 12px;
        transform: translate($translateX, calc(-1 * ($border-width + $padding)));
    }

    &::after { // Active step number
        top: 50%;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 3.5rem;
        height: 3rem;
        background-color: #FFB701;
        border-top-right-radius: 18px;
        border-bottom-right-radius: 18px;
        font-size: 1.8rem;
        font-weight: 900;
        line-height: 1;
        transform: translate(calc(100% + $translateX), -50%);
    }

    @for $step-number from 1 through 9 {

        &--step-#{$step-number} { // Get step number through dynamic class

            &::after {
                content: '#{$step-number}';
            }
        }
    }

    &--active { // Active state

        &::before,
        &::after { // Active step border & number
            opacity: 1;
        }

        &::before { // Active step border
            transform: translate(0, calc(-1 * ($border-width + $padding)));
        }

        &::after { // Active step number
            transform: translate(calc(100% - 1px), -50%);
        }
    }
}

// --------------------------------------------------
// STYLE
// --------------------------------------------------

app-activity-edition-preview {
    // Layout
    position: relative;
    z-index: 1;
    flex: none;
    align-self: flex-start;
    height: 100%;
    min-height: 55rem;
    max-height: 95rem;
    aspect-ratio: 1 / 2;
    // Style
    line-height: 1.2;

    .activity-edition-preview__smartphone-wrapper {
        // Layout
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 1.2rem;
        width: 100%;
        min-width: min-content;
        height: 100%;
        padding: 15% 5% 10%;
        // Style
        color: $default-font-color;
        background-image: image('backgrounds/smartphone-mockup.svg');
        background-size: 100% 100%;

        .activity-edition-preview__header {
            flex: none;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            gap: 0.3rem;
            padding: 0 0.6rem;

            .activity-edition-preview__title {
                @include visually-hidden;
            }

            .activity-edition-preview__fake-close-button,
            .activity-edition-preview__fake-help-button {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: rgba(187, 204, 216, 0.25);
                color: rgba($secondary-c, 0.2);
                border-radius: 8px;
                margin-bottom: auto;
                padding: 0.6rem 1.2rem;
            }

            .activity-edition-preview__fake-close-button {

                .activity-edition-preview__close-icon {
                    transform: scale(1.4);
                }
            }

            .activity-edition-preview__activity-title {
                @include placeholder-element-style;
                @include activable-element-style;
                margin-top: calc($activity-edition-preview__activable-element--border-width + $activity-edition-preview__activable-element--padding);
                margin-bottom: unset;
                font-size: 1.4rem;
                font-weight: 600;
                line-height: 1;
                text-align: center;
                overflow-wrap: anywhere; // Prevent unbreakable string overflow
            }
        }

        .activity-edition-preview__debug {
            word-break: break-all;

            .mat-expansion-panel {
                border-radius: unset;
                background-color: orange;

                .mat-expansion-panel-header {
                    height: unset;
                    background-color: inherit !important;

                    .mat-expansion-panel-description {

                        p {
                            margin: unset;
                        }
                    }
                }

                .mat-expansion-panel-content {
                    padding: unset;

                    .mat-expansion-panel-body {

                        pre {
                            line-height: 1;
                        }
                    }
                }
            }
        }

        .activity-edition-preview__content {
            overflow: visible;
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 1.2rem;

            .activity-edition-preview__instruction {
                @include placeholder-element-style;
                @include activable-element-style;
                flex: none;
                display: flex;
                align-items: center;
                gap: 0.6rem;

                .activity-edition-preview__instruction-icon {
                    margin-bottom: auto;
                    color: $accent-c;
                }

                .activity-edition-preview__instruction-text {
                    margin: unset;
                    font-weight: 900;
                    overflow-wrap: anywhere; // Prevent unbreakable string overflow
                }
            }

            .activity-edition-preview__media {
                @include placeholder-element-style;
                @include activable-element-style;
                flex: none;

                .activity-edition-preview__image-media,
                .activity-edition-preview__video-media,
                .activity-edition-preview__audio-media-wrapper,
                .activity-edition-preview__text-media-wrapper,
                .activity-edition-preview__media-placeholder {
                    width: 100%;
                    height: auto;
                    aspect-ratio: 16/9;
                    border-radius: 12px;
                }
                .activity-edition-preview__flashcard-media-wrapper {
                    @include default-typology-preview-layout;
                }
                .activity-edition-preview__flashcard-media {
                    figure {
                        margin: 0;
                        flex: 1;
                        position: relative;
                    }
                    img {
                        position: absolute;
                        z-index: 0;
                        width: 100%;
                        height: auto;
                        max-height: 100%;
                        aspect-ratio: 16 / 9;
                        object-fit: cover;
                    }
                }

                .activity-edition-preview__image-media,
                .activity-edition-preview__video-media {
                    object-fit: cover;
                }

                .activity-edition-preview__audio-media-wrapper,
                .activity-edition-preview__text-media-wrapper,
                .activity-edition-preview__media-placeholder {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    background-color: rgba($secondary-c, 0.2);
                }

                .activity-edition-preview__audio-media-wrapper {
                    justify-content: flex-end;
                    gap: 1.2rem;
                    padding: 1.2rem;

                    .activity-edition-preview__audio-icon {
                        flex: none;
                        width: 100%;
                        min-width: unset;
                        height: auto;
                        min-height: unset;
                        max-height: 35%;
                        margin: auto 0;
                        opacity: 0.5;

                        svg {
                            width: 100%;
                            min-width: unset;
                            height: 100%;
                            min-height: unset;
                        }
                    }

                    .activity-edition-preview__audio-media {
                        flex: none;
                        width: 100%;
                    }
                }

                .activity-edition-preview__text-media-wrapper,
                .activity-edition-preview__media-placeholder {
                    gap: 0.6rem;
                    padding: 1.2rem 2.4rem;

                    .activity-edition-preview__text-media,
                    .activity-edition-preview__media-placeholder-text {
                        margin: unset;
                        line-height: 1.2;
                        text-align: center;
                        overflow-wrap: anywhere; // Prevent unbreakable string overflow
                        > p {
                            text-align: left;
                        }
                        b {
                            font-weight: 700;
                            line-height: inherit;
                        }
                        li {
                            text-align: left;
                        }
                    }
                }

                .activity-edition-preview__media-placeholder {

                    .activity-edition-preview__media-placeholder-icon {
                        flex: none;
                        width: 3rem;
                        min-width: unset;
                        height: auto;
                        min-height: unset;
                        aspect-ratio: 1;

                        svg {
                            width: 100%;
                            min-width: unset;
                            height: 100%;
                            min-height: unset;
                        }
                    }
                }
            }

            .activity-edition-preview__typology {
                @include placeholder-element-style;
                @include activable-element-style;
                margin: auto 0;
            }
        }

        .activity-edition-preview__footer {
            flex: none;
            display: flex;
            justify-content: center;

            .activity-edition-preview__fake-validate-button {
                @include primary-button; // Button mixin from Activities module
                justify-content: center;
                min-width: 35%;
                min-height: unset;
                padding: 0.6rem 1.2rem;
                pointer-events: none;
            }
        }
    }
}