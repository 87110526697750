@use "sass:map";
$background-component: #DBEAF5 !default;
app-activity-edition-horizontal-selector {
    display: flex;
    justify-content: space-between;
    padding: 0.25rem;

    border-radius: 0.75rem;
    border: 1px solid #E1E2E8;
    background: $background-component;
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.05) inset;

    mat-tab-group.mat-primary {

        .mat-ink-bar {
            visibility: hidden !important;
        }
        
        .mdc-tab {
            opacity: unset;
        }

        .mat-mdc-tab-labels {
            gap: $gap-xs;
            
            .mdc-tab__content {
                gap: $gap-m;
                padding-bottom: unset;
            }
        }

        .mdc-tab:not(:hover) {
            @include button("tertiary", $size: m);
            flex: 1;
            justify-content: center;
            padding: map.get($button-padding, m);
            margin: 0;

        }

        .mdc-tab:hover,
        .mdc-tab.mdc-tab--active {
            @include button("dark", $size: m);
            flex: 1;
            justify-content: center;
            margin: 0;
        }
    }
}