app-activity-edition-short-answer {
    display: grid !important;
    grid-template-columns: 1fr auto;
    grid-auto-rows: auto;
    column-gap: 1rem !important;
    row-gap: 1.5rem !important;

    .activity-edition-short-answer__text-input {
        grid-column: 1 /span 1;
    }

    .activity-edition-short-answer__checkbox {
        @include button($type: "tertiary");
        grid-column: 2 /span 1;
        grid-row: 1 / span 1;
    }

    .activity-edition-short-answer__add-button {
        @include button(
            $type: "tertiary",
            $link-style: true,
        );
        grid-column: 1 /span 2;
        margin: 0 auto;
        text-decoration: underline;
    }
}