// --------------------------------------------------
// VARIABLES
// --------------------------------------------------

$activity-editor-panel--gap: 3rem !default;
$activity-editor-panel--horizontal-padding: 3rem !default;
$activity-editor-panel--vertical-padding: 6rem !default;

// --------------------------------------------------
// STYLE
// --------------------------------------------------

app-activity-editor-panel {
    // Layout
    overflow: hidden;
    flex: 1;
    display: flex;
    flex-direction: row;
    gap: $activity-editor-panel--gap;
    min-height: 60rem;
    padding: $activity-editor-panel--vertical-padding $activity-editor-panel--horizontal-padding;
    // Style
    background-color: $primary-c;
}