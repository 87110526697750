@use "sass:map";

// --------------------------------------------------
// DEFAULT VALUES
// --------------------------------------------------
$app-activity-edition-form__header-top-radius: $radius-xl;

$background-component: #DBEAF5 !default;
$app-activity-edition-form__header-background: #E6F1F9 !default;

$app-activity-edition-form__field-section-gap: $gap-l;
$app-activity-edition-form__field-wrapper-gap: $gap-l;
$app-activity-edition-form__section_padding-gap: $gap-l + $app-activity-edition-form__field-wrapper-gap !default;
$app-activity-edition-form__section_padding: $app-activity-edition-form__section_padding-gap $app-activity-edition-form__section_padding-gap $app-activity-edition-form__section_padding-gap $app-activity-edition-form__section_padding-gap + $app-activity-edition-form__field-wrapper-gap !default;

$field-wrapper-hightlighter-bgcolor: #E6F1F9 !default;
$field-wrapper-hightlighter-bgcolor-active: #FFDB80 !default;
$field-wrapper-hightlighter-size: $app-activity-edition-form__field-wrapper-gap !default;

// --------------------------------------------------
// MIXINS
// --------------------------------------------------

// --------------------------------------------------
// Placeholder
// --------------------------------------------------
@mixin radio_on {
    background: transparent icon('radio_on.svg') no-repeat center center;
}
@mixin radio_off {
    background: transparent icon('radio_off.svg') no-repeat center center;
}


// --------------------------------------------------
// COMPOSANT
// --------------------------------------------------
app-activity-edition-form {
    // Layout
    overflow: hidden;
    flex: 1;
    display: flex;
    flex-direction: column;
    // Style
    color: $default-font-color;

    section, .section {
        counter-increment: listCounter;
        gap: 10px;
        display: flex;
        flex-direction: column;
        position: relative;
        // Style
        background: $background-component;
        padding: $app-activity-edition-form__section_padding;

        &:empty {
            display: none;
        }

        & > *   {
            z-index: 1;
            position: relative;
        }


        &::before {
            content: ' ';
            display: block;
            inset: $app-activity-edition-form__field-section-gap 0 0 $icon-size-l;
            background-color: $field-wrapper-hightlighter-bgcolor;
            width: calc(100% - $icon-size-l);
            height: calc(100% - 2.4rem);
            position: absolute;
            z-index: 0;
            border-top-left-radius: $radius-rounded;
            border-bottom-left-radius: $radius-rounded;
        }
        &.active::before, &.active::before {
            background-color: $field-wrapper-hightlighter-bgcolor-active;
        }
    }

    form {
        counter-reset: listCounter;
        display: flex;
        flex-direction: column;

        section, .section {
            &:empty {
                display: none;
            }
            &::after
            {
                content: counter(listCounter);
                position: absolute;
                top: 2.8rem;
                left: 0;
                width: 3.4rem;
                padding: 0.5rem;
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 2;

                // Style
                color: white;
                background: black;
                border-top-right-radius: $radius-rounded;
                border-bottom-right-radius: $radius-rounded;
                font-size: 18px;
                font-style: normal;
                font-weight: 900;
                line-height: normal;
            }
            &.active::after {
                color: black;
                background: var(--Jaune, #FFB800);
                border: 2px solid $background-component;
                border-left: 2px solid var(--Jaune, #FFB800);
            }

        }

    }

    // --------------------------------------------------
    // BUTTONS
    // --------------------------------------------------
    // Generic
    button,
    [class*="activity-edition-form__btn-"] {
        @include button("tertiary");
    }

    button.activity-edition-form__btn-onQuitClick { // Close X
        @include button("tertiary", $size: s, $text-hide: true);
    }

    button.activity-edition-form__btn-canClickOnDoneButton { // Suivant
        @include button("primary", $size: m, $fullwidth: true);
        border-radius: $radius-rounded;
    }
    button.activity-edition-form__btn-onValidAndQuitClick { // Valider et quitter
        @include button("primary", $size: m);
        border-radius: $radius-rounded;
    }


    // --------------------------------------------------
    // HEADER
    // --------------------------------------------------

    header {
        // Layout
        display: flex;
        gap: 5px;
        padding: 0.8rem 1.6rem;
        border-top-left-radius: $app-activity-edition-form__header-top-radius; // var(--radius-xl);
        border-top-right-radius: $app-activity-edition-form__header-top-radius; // var(--radius-xl);
        background-color: $app-activity-edition-form__header-background;
        h2 {
            margin-right: auto;
            font-size: 1.8rem;
            font-weight: 800;
        }

    }
    footer {
        display: flex;
        justify-content: flex-end;
        gap: 5px;
        padding: 0.8rem 1.6rem;
        border-bottom-left-radius: $app-activity-edition-form__header-top-radius; // var(--radius-xl);
        border-bottom-right-radius: $app-activity-edition-form__header-top-radius; // var(--radius-xl);
        background-color: $app-activity-edition-form__header-background;
    }


    // --------------------------------------------------
    // MAIN CONTENT
    // --------------------------------------------------

    main.activity-edition-form__content {
        overflow: auto;
        flex: 1;
        background-color: $background-component;
    }






    // --------------------------------------------------
    // LAYOUT of Spécific Typologies Fields
    // --------------------------------------------------
    section.typologies-fields {
        display: flex;
        flex-direction: column;
        > * { // activities fields components
            display: flex;
            flex-direction: column;
            gap: 0.75rem;
        }

        app-activity-edition-horizontal-selector {
            display: flex;
            overflow: auto;
        }

        app-activity-editor-text-input {
            flex: 2;
            display: flex;
            justify-content: space-between;
            @include app-activity-edition-form__field;


        }



        .grid-fields {
            @include grid(
                $xs-col: 1,
                $xs-row: 1,
                $sm-col: 1,
                $sm-row: 1,
                $lg-col: 2,
                $lg-row: 1,
                $xl-col: 2,
                $xl-row: 1,
                $gap: 20);
        }

    }

}