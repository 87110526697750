app-activity-edition-preview-qcm {
    @include default-typology-preview-layout;

    app-flashcard.button-image {
        figure {
            margin: 0;
            flex: 1;
            position: relative;
        }
        img {
            position: absolute;
            z-index: 0;
            width: 100%;
            height: auto;
            max-height: 100%;
            aspect-ratio: 16 / 9;
            object-fit: cover;
        }
    }

    .activity-edition-preview-qcm__answer-button,
    .activity-edition-preview-qcm__answer-button-placeholder {}
}