@use '@angular/material' as mat;

app-activity-edition-flashcard-list {
    display: flex;
    flex-direction: column;
    gap: $gap-m;

    .field-wrapper {
        flex: 1;
        @include field-wrapper-answer-type-flashcard;
        .active & {
            background-color: lighten($field-wrapper-hightlighter-bgcolor-active, 10%);
        }
    }

    .activity-edition-flashcard-list_button-create-wrapper {
        @include app-activity-edition-form__field;
        justify-content: center;
        .activity-edition-flashcard-list_button-create {
            color: map_get($accent, 500);
            mat-icon.mat-icon svg {
                color: map_get($accent, 500) !important;
            }
        }
    }
    .activity-edition-form__btn-onDissociateClick {
        @include radio-in-button;
        color: map-get($accent, 500);
    }
}