@use "sass:map";

app-activity-edition-qcm-qcmu {

    .grid-fields {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: minmax(0, 1fr);
        justify-content: center;
        align-items: center;
        gap: $gap-m;
    }

    // --------------------------------------------------
    // FIELD INPUT WRAPPER ANSWERS TYPE TEXT
    // --------------------------------------------------
    .answer-type-text .field-wrapper {
        @include field-wrapper-answer-type-text;
        &:empty {
            display: none;
        }
        button.activity-edition__btn-onSelect { // bonne réponse
            @include button("tertiary", $size: m, $link-style: true);
            flex: 1;
            min-width: 170px;
            max-width: 100%;
            justify-content: flex-end;
            font-size: 1.2rem;
            padding: inherit;
            @include radio-in-button;
        }
    }

    // --------------------------------------------------
    // FIELD INPUT WRAPPER ANSWERS TYPE IMAGE
    // --------------------------------------------------
    .answer-type-image .field-wrapper, .answer-type-audio .field-wrapper {
        @include field-wrapper-answer-type-image-and-audio;
        background-color: $background-component;

        .active & {
            @include field-wrapper-answer-type-image-and-audio;
            background-color: lighten($field-wrapper-hightlighter-bgcolor-active, 10%);
        }

        &:empty {
            display: none;
        }
        app-image-upload, .app-image-upload {
            flex: 1;
            min-width: 50%;
        }
        button.activity-edition-form__btn-onSelect { // bonne réponse
            font-size: 1.2rem;
            padding: inherit;
            flex: 1;
            min-width: 100px;
            align-self: flex-end;
            justify-content: flex-start;
            margin-bottom: $gap-s;
            @include radio-in-button;
        }

    }

    // --------------------------------------------------
    // FIELD INPUT WRAPPER ANSWERS TYPE FLASHCARD
    // --------------------------------------------------
    .answer-type-flashcard .field-wrapper {
        @include field-wrapper-answer-type-flashcard;
        background-color: $background-component;

        .active & {
            // @include field-wrapper-answer-type-flashcard;
            background-color: lighten($field-wrapper-hightlighter-bgcolor-active, 10%);
        }

        &:empty {
            display: none;
        }
        //app-image-upload, .app-image-upload {
        //    flex: 1;
        //    min-width: 50%;
        //}
        button.activity-edition-form__btn-onSelect { // bonne réponse
            font-size: 1.2rem;
            padding: inherit;
            flex: 1;
            min-width: 100px;
            align-self: flex-end;
            justify-content: flex-start;
            margin-bottom: $gap-s;
            @include radio-in-button;
        }

    }
}